.dot {
  height: 2px;
  widht: 2px;
  border-radius: 50%;
  gap: 1px;
  color: #799fc3;
}

.vertical {
  margin-top: -10px;
}
