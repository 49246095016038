.preview-order-table {
  width: 45%;
  flex-direction: row;
  align-items: center;
}

.report-preveiw-api .preview-order-table {
  width: calc(100% - 15px);
}

.preview-patient-sample-information-header {
  width: 100%;
  height: 15px;
  border: 2.5px solid #799fc3;
  background-color: #033a5d;
  border-radius: 20px;
  display: flex;
  color: white;
  font-size: 10px;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  font-weight: 600;
  font-family: "Bold";
  /* letter-spacing: 0.3px; */
  padding-top: 2px;
  padding-bottom: 4px;
  margin-bottom: 10px;
}

.report-preveiw-api .preview-patient-sample-information-header {
  height: 23px;
  font-size: 13px;
  border: 3.5px solid #799fc3;
  padding-top: 5px;
  padding-bottom: 1px;
}

.preview-filed-value {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  margin-top: 2px;
  padding-bottom: 2px;
  font-family: "Bold";
  align-self: start;
  margin-left: 10px;
  gap: 15px;
  /* font-weight: 600; */
  font-size: 10px;
}

.preview-filed {
  font-family: "Normal";
  font-size: 8px;
  /* font-weight: 600; */
  /* letter-spacing: 0.6px; */
}

.report-preveiw-api .preview-filed {
  font-size: 10px;
  padding-top: 4px;
  padding-bottom: 0px;
}

.preview-under-line {
  width: 92%;
  height: 0.5px;
  /* border-top: 0.05px solid rgb(118, 160, 196); */
  /* background-color: #799fc3; */
  background-color: rgb(118, 160, 196);
  margin-left: 10px;
  /* margin-top: 2px; */
  /* opacity: 0.3; */
}

.preview-filed-value {
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  justify-content: start;
  align-items: center;
  /* margin-top: 2px; */
  padding: 2px 0;
  padding-top: 0;
  font-family: "Bold";
  align-self: start;
  margin-left: 10px;
  gap: 15px;
  /* font-weight: 600; */
  font-size: 10px;
  min-height: 13px;
  /* background-color: blueviolet; */
}

.report-preveiw-api .preview-filed-value {
  min-height: 15px;
  padding-top: 1px;
}

.preview-filed {
  font-family: "Normal";
  font-size: 8px;
  /* font-weight: 600; */
  /* letter-spacing: 0.6px; */
  text-align: start;
  margin-left: 10px;
  width: 110px;
  /* background-color: red; */
  /* border: red 1px solid; */
}

.report-preveiw-api .preview-filed {
  font-size: 10px;
}


.preview-input-value {
  font-family: "Bold";
  font-weight: 600;
  font-size: 10px;
  /* letter-spacing: 0.5px; */
  text-align: start;
  width: 100px;
  margin-bottom: 1px;
}

.report-preveiw-api .preview-input-value {
  font-size: 12px;
  width: 150px;
  font-family: "Normal";
  /* font-weight: initial; */
  padding-top: 4px;
  margin-bottom: -2px;
}

