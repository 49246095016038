.preview-chart-container {
  overflow: visible;
  width: 520px;
  margin-left: 20px;
  margin-top: 5px;
}

.report-preveiw-api .preview-chart-container {
  margin-top: 0;
}

.report-preveiw-api .preview-chart-container {
  width: 700px;
}

.report-preveiw.pdf-download .preview-chart-container *,
.preview-chart-container * {
  font-family: Arial, Helvetica, sans-serif !important;
  
  color: rgb(33, 33, 33) !important;
}

.preview-chart-container .main-chart,
.preview-chart-container .datepoint-container {
  /* min-width: 800px; */
}

.preview-chart-container .main-chart {
  position: relative;
  padding-top: 10px;
}

.preview-chart-container .main-chart .y-legend {
  position: absolute;
  left: 39px;
  font-weight: bold;
  top: 34%;
  font-size: 7pt;
}
.report-preveiw-api .preview-chart-container .main-chart .y-legend {
  left: 30px;
}
.preview-chart-container .main-chart .y-legend img {
  width: 6px;
}

.report-preveiw-api .preview-chart-container .main-chart .y-legend img {
  width: 8px;
}

.preview-chart-container .main-chart .main-chart-row {
  height: 11px; /***** Related to Gen Line ********/
  display: grid;
  grid-template-columns: 70px 1fr;
  align-items: center;
}
.report-preveiw-api .preview-chart-container .main-chart .main-chart-row {
  height: 16px; /****** Related to Gen Line *******/
}
.preview-chart-container .main-chart-row .row-item-left {
  text-align: right;
  padding: 0 5px;
  font-size: 5pt;
  display: grid;
  align-items: center;
}
.report-preveiw-api .preview-chart-container .main-chart-row .row-item-left {
  font-size: 7pt;
}
.report-preveiw.pdf-download .preview-chart-container .main-chart-row .row-item-left > span {
  margin-top: -3px;
}

.preview-chart-container .main-chart-row .row-item-right {
  background-color: #819ec0;
  height: 0.25px;
}

.preview-chart-container .main-chart-row.row-item-200,
.preview-chart-container .main-chart-row.row-item-100 {
  margin-top: 24px; /******* Related to Gen Line ******/
}

.report-preveiw-api .preview-chart-container .main-chart-row.row-item-200,
.report-preveiw-api .preview-chart-container .main-chart-row.row-item-100 {
  margin-top: 30px; /******* Related to Gen Line ******/
}

.preview-chart-container .main-chart-row.row-item-250,
.preview-chart-container .main-chart-row.row-item-145 {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.preview-chart-container .main-chart-row.row-item-250 {
  bottom: 52.5px;
}
.report-preveiw-api .preview-chart-container .main-chart-row.row-item-250 {
  bottom: 68.5px;
}
.preview-chart-container .main-chart-row.row-item-145 {
  bottom: calc((36px / 100) * 45); /* 36px is each line gap, 45 is is how much height it will be shown */
  /*************/
}

.report-preveiw-api .preview-chart-container .main-chart-row.row-item-145 {
  bottom: calc((47px / 100) * 45); /* 36px is each line gap, 45 is is how much height it will be shown */
  /*************/
}

.preview-chart-container .main-chart-row.row-item-250 .row-item-left,
.preview-chart-container .main-chart-row.row-item-145 .row-item-left {
  font-weight: bold;
  position: relative;
}

.preview-chart-container .main-chart-row.row-item-250 .row-item-left .left-label,
.preview-chart-container .main-chart-row.row-item-145 .row-item-left .left-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  width: 100px;
}

.preview-chart-container .main-chart-row.row-item-250 .row-item-left .left-label span,
.preview-chart-container .main-chart-row.row-item-145 .row-item-left .left-label span {
  display: block;
  font-size: 5pt;
}

.report-preveiw-api .preview-chart-container .main-chart-row.row-item-250 .row-item-left .left-label span,
.report-preveiw-api .preview-chart-container .main-chart-row.row-item-145 .row-item-left .left-label span {
  font-size: 6pt;
}

.preview-chart-container .main-chart-row.row-item-250 .row-item-right,
.preview-chart-container .main-chart-row.row-item-145 .row-item-right {
  height: 0;
  background-color: transparent;
  border-top: 1px dashed rgb(121, 159, 195);
}

.preview-chart-container .main-chart-row.row-item-145 .row-item-right {
  border: none;
  height: 0.25px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(3px, 1fr));
  gap: 3px;
}

.preview-chart-container .main-chart-row.row-item-145 .row-item-right > div.dot {
  border-radius: 0;
  background-color: rgb(221, 230, 240);
  height: 0.25px;
}


.preview-chart-container .left-bar {
  position: absolute;
  left: 70px;
  top: 10px;
  width: 0.25px;
  height: 165px;/*************/
  background-color: rgb(0, 58, 93);
}

.report-preveiw-api .preview-chart-container .left-bar {
  height: 227px;
}

.preview-chart-container .bottom-bar {
  position: absolute;
  left: 70px;
  bottom: -7px;
  height: 0.25px;
  width: calc(100% - 70px);
  background-color: rgb(0, 58, 93);
}
/* ------------------------- */
.preview-chart-container .datepoint-container {
  min-height: 20px;
  padding: 17px 15px 4px 60px;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, 120px); */
  grid-template-columns: repeat(8, 1fr);
  position: relative;
  gap: 16px;
}

.report-preveiw-api .preview-chart-container .datepoint-container {
  padding: 17px 0px 4px 60px;
  gap: 14px;
}

.preview-chart-container .datepoint-container .date-legend {
  position: absolute;
  left: 25px;
  top: 16px;
}

.preview-chart-container .datepoint-container .date-legend span strong {
  font-size: 7px !important;
}

.report-preveiw-api .preview-chart-container .datepoint-container .date-legend span strong {
  font-size: 9px !important;
}

.preview-chart-container .datepoint-container .pre-treatment {
  position: absolute;
  bottom: 4px;
  left: 60px;
}

.preview-chart-container .datepoint-container * {
  box-sizing: border-box;
  font-size: 8px;
}
.report-preveiw-api .preview-chart-container .datepoint-container * {
  font-size: 10px;
}
.preview-chart-container .datepoint-item {
  position: relative;
  display: grid;

  justify-items: center;
  padding: 0 5px;
}

.preview-chart-container .datepoint-item > span {
  font-size: 7px;
  font-weight: bold;
}

.report-preveiw-api .preview-chart-container .datepoint-item > span {
  font-size: 9px;
}

.preview-chart-container .datepoint-item > span.day-cycle {
  font-size: 8px;
  font-weight: bold;
  margin-top: 3px;
  min-height: 9.5px;
}

.report-preveiw-api .preview-chart-container .datepoint-item > span.day-cycle  {
  font-size: 9px;
}

.preview-chart-container .datepoint-item::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 0.75px;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(0, 58, 93);
}

.preview-chart-container .datepoint-item .point {
  font-size: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: #dce6f0;
  z-index: 50;
  font-weight: 700;
  padding: 0 4px;
  color: #211e1f;
  border: solid 0.25px rgb(0, 58, 93);
  border-radius: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.report-preveiw.pdf-download .preview-chart-container .datepoint-item .point > span {
  margin-top: -1.5px;
}

.preview-chart-container .datepoint-item .point * {
  padding: 0;
  margin: 0;
}

.preview-chart-container .datepoint-item input,
.preview-chart-container .datepoint-item button {
  width: 120px !important;
  border: 2px solid #384149;
  border-radius: 5px;
  padding: 2px 5px;
}

.preview-chart-container .last-box {
  position: absolute;
  top: -186px;
  bottom: -2px;
  width: 100%;
  border: 1.5px solid rgb(31, 31, 31);
  z-index: -1;
}
.report-preveiw-api .preview-chart-container .last-box {
  top: -245px;
}
.preview-chart-container .last-box.last-box-0 {
  width: 45px;
}

.pdf-download .preview-chart-container .last-box {
  z-index: 1;
}

.preview-input-date {
  font-family: "Normal";
  font-weight: 600;
}
