.preview-patient-order-by {
  margin-top: 18px;
  display: flex;
  justify-content: start;
  gap: 1.5rem;
  width: 100%;
  flex-direction: row;
  margin-left: 25px;
}

.report-preveiw-api .preview-patient-order-by {
  margin-left: 0;
  margin: 10px 15px;
  margin-top: 0;
  /* padding-top: 15px; it's okay for localhost */
  padding-top: 5px;
  margin-bottom: -5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.preview-patient {
  width: 45%;
}

.report-preveiw-api .preview-patient {
  width: calc(100% - 15px);
}

.preview-patient-name-field {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  margin-left: 5px;
  align-items: center;
}

.report-preveiw-api .preview-patient-name-field {
  margin-top: 3px;
  margin-bottom: 0px;
}

.preview-patient-name {
  font-family: "Normal";
  font-weight: 600;
  font-size: 10px;
}

.report-preveiw-api .preview-patient-name {
  font-weight: 400;
  font-size: 13px;
}

.preview-patient-name-value {
  font-family: "Bold";
  font-weight: 600;
  margin-left: 2px;
  font-size: 11px;
}
.report-preveiw-api .preview-patient-name-value {
  font-family: "Normal";
  font-size: 13px;
}
.preview-date-of-birth {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;
  margin-left: 10px;
}
.report-preveiw-api .preview-date-of-birth {
  margin-left: 15px;
}
.preview-sex {
  width: 19%;
  margin-right: 20px;
}
.preview-patient-id {
  width: 35%;
  display: flex;
  justify-content: left;
  margin-left: -8px;
}

.preview-table-input {
  display: flex;
  font-family: "Bold";
  margin-top: 3px;
  font-size: 8px;
  margin-bottom: 5px;
}
.report-preveiw-api .preview-table-input {
  margin: 0;
  padding-top: 7px;
}
.preview-date-of-birth-field {
  font-size: 10px;
  border: none;
  margin-left: 12px;
  margin-right: 17px;
  text-align: center;
  font-family: "Bold";
  font-weight: 600;
}
.report-preveiw-api .preview-date-of-birth-field {
  margin-right: 70px;
  font-size: 12px;
  font-family: "Normal";
  font-weight: 550;
}
.preview-sex-field {
  width: 20%;
  font-size: 10px;
  text-align: center;
  font-family: "Bold";
  font-weight: 600;
}
.report-preveiw-api .preview-sex-field {
  font-size: 12px;
  font-family: "Normal";
  font-weight: 550;
}
.preview-patient-id-field {
  border: none;
  text-align: center;
  font-family: "Bold";
  font-weight: 600;
  font-size: 10px;
  margin-left: 12px;
}
.report-preveiw-api .preview-patient-id-field {
  margin-left: 15px;
  font-size: 12px;
  font-family: "Normal";
  font-weight: 550;
}

.report-preveiw-api .vertical {
  margin-top: -8px;
}


.preview-table-header-input-data {
  width: 100%;
}

.preview-vertical-dot {
  height: 30px;
  width: 10px;
  border: 1px solid blue;
}

.preview-table-header {
  display: flex;
  color: white;
  margin-top: 5px;
  width: 100%;
  height: 15px;
  border-radius: 20px;
  border: 2.5px solid #799fc3;
  background-color: #013a5d;
  text-align: center;
  align-items: center;
  align-content: center;
  font-size: 10px;
  font-family: "Normal";
  font-weight: 600;
  padding-top: 2px;
  padding-bottom: 4px;
  /* letter-spacing: 0.8px; */
}

.report-preveiw-api .preview-table-header {
  height: 23px;
  font-size: 13px;
  border: 3.5px solid #799fc3;
  padding-top: 5px;
  padding-bottom: 1px;
}

.preview-order-by {
  width: 40%;
  margin-left: 5px;
}
.report-preveiw-api .preview-order-by {
  width: calc(100% - 30px);
  margin-left: 0;
}
.preview-orderby-name-field {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-family: "Normal";
  font-weight: 600;
  margin-bottom: 5px;
  align-items: center;
}
.report-preveiw-api .preview-orderby-name-field {
  margin-top: 3px;
  margin-bottom: 0px;
}
.preview-order-by-name {
  font-weight: 600;
  font-size: 10px;
  font-family: "Normal";
  margin-left: 7px;
}

.report-preveiw-api .preview-order-by-name {
  font-size: 13px;
  font-weight: 400;
}

.preview-order-by-name-value {
  font-weight: 600;
  margin-left: 2px;
  font-family: "Medium";
  font-size: 11px;
  word-spacing: 0px;
  letter-spacing: 0px;
}

.report-preveiw-api .preview-order-by-name-value {
  font-size: 13px;
  font-family: 'Normal';
}

.preview-report-recipient {
  width: 50%;
  font-family: "Normal";
  display: flex;
  justify-content: left;
  margin-left: 10px;
}

.report-preveiw-api .preview-report-recipient {
  margin-left: 15px;
}

.preview-contact-info {
  width: 50%;
  font-family: "Normal";
  display: flex;
  justify-content: left;
  margin-left: 10px;
}

.preview-report-recipient-field {
  width: 55%;
  border: none;
  text-align: start;
  margin-right: 5px;
  margin-left: 10px;
  height: 60px;
  font-family: "Bold";
  font-size: 8px;
  font-weight: 600;
  word-spacing: 1px;
  text-align: left;
}

.preview-contact-info-field {
  width: 50%;
  text-align: start;
  margin-left: 7px;
  height: 60px;
  font-family: "Bold";
  margin-right: 10px;
  font-size: 9px;
  font-weight: 600;
  word-spacing: 1px;
}

.preview-report-recipient-field-api {
  width: 55%;
  border: none;
  text-align: start;
  margin-right: 5px;
  margin-left: 10px;
  height: 70px;
  font-family: "Bold";
  font-size: 11px;
  font-weight: 600;
  word-spacing: 1px;
  text-align: left;
}

.report-preveiw-api .preview-report-recipient-field-api {
  font-size: 12px;
  height: 65px;
  font-family: "Normal";
  font-weight: 550;
}

.preview-contact-info-field-api {
  width: 50%;
  text-align: start;
  margin-left: 7px;
  height: 70px;
  font-family: "Bold";
  margin-right: 10px;
  font-size: 11px;
  font-weight: 600;
  word-spacing: 1px;
}

.report-preveiw-api .preview-contact-info-field-api {
  height: 65px;
  font-family: "Normal";
  font-weight: 550;
}

.preview-table-header-input-data .preview-amended-notes {
  border: 0.25px solid #033a5d;
  font-size: 7px;
  border-radius: 70px;
  height: 20px;
  padding: 1px 12px;
  background-color: #DEEBF7;
}

.preview-table-header-input-data .preview-amended-notes span.notes-content {
  word-spacing: 2px;
}

.report-preveiw-api .preview-table-header-input-data .preview-amended-notes {
  margin-top: 5px;
  height: 30px;
  font-size: 9px;
}