.preview-unproofed {
  font-family: "Bold";
  font-size: 11.5pt;
  color: #d2232a;
  margin-left: 12px;
  font-weight: 900;
}

.preview-divitum-tka {
  display: flex;
}
.preview-divitum {
  font-size: 16.95pt;
  font-family: "Bold";
  font-weight: 700;
  display: flex;
}
.preview-divitum img {
  width: 160px;
}
.report-preveiw-api .preview-divitum img {
  width: 230px;
}
.preview-r-circle-header {
  font-size: 10px;
  font-weight: 600;
  /* margin-top: -10px; */
  margin-bottom: 5px;
  /* margin-left: 3px; */
  /* padding-left: 0px; */
  margin-left: -5px;
  font-family: "Light";
}

.preview-tka-text {
  font-size: 16.95pt;
  font-family: "Bold";
  font-weight: 700;
  padding-left: 4px;
}