.constant-section {
  width: 90%;
  margin-left: 80px;
  margin-bottom: 50px;
}

.chart-static-note {
  font-size: 12px;
  font-weight: 600;
  font-family: "Normal";
}

.horizontal-dotted-line {
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px dotted #799fc3;
}

.general-test-information {
}

.common-header {
  font-family: "Medium";
  font-size: 14px;
  font-weight: 600;
  margin-left: 25px;
}

.warnings-limitations {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.list {
  font-family: "Normal";
  font-size: 12px;
  font-weight: 600;
  margin-top: 2px;
}

.reference-section {
  font-size: 12px;
  font-weight: 600;
  font-family: "Normal";
}

.reference {
  font-weight: 800;
  font-family: "Medium";
  font-size: 13px;
}

.divider {
  width: 100%;
  height: 20px;
  background-color: #013a5d;
  border-radius: 20px;
  margin-top: 20px;
}

.footer {
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
}

.footer-left {
  width: 75%;
  font-size: 12px;
  font-family: "Normal";
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 1px;
}

.left-footer-text {
  margin-bottom: 15px;
}

.bdt1002 {
  margin: 0 10px;
}

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: end;
  font-weight: 600;
}

.biovica-logo img {
  margin-top: -15px;
  height: 38px;
  margin-left: -10px;
}

.address {
  margin-top: -40px;
  font-family: "Normal";
  font-size: 12px;
  text-align: start;
  display: flex;
  flex-direction: column;
}
.biovica-web-link {
  text-decoration: none;
  color: black;
  font-family: "Normal";
  font-size: 10px;
  margin-top: 5px;
}
.left-footer-copyright {
  display: flex;
}

.r-circle {
  font-size: 7px;
  margin-top: -2px;
  margin-right: 1px;
  margin-left: -1.5px;
}
