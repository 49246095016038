.patient-order-by {
  margin-top: 50px;
  display: flex;
  justify-content: start;
  gap: 2rem;
  width: 100%;
  flex-direction: row;
  margin-left: 80px;
}

.patient {
  width: 45%;
}
.patient-name-field {
  display: flex;
  flex-direction: row;
}

.patient-name {
  font-family: "Medium";
  font-weight: 600;
  letter-spacing: 0.5px;
}

.patient-name-value {
  font-family: "Bold";
  font-weight: 600;
  font-size: 16px;
  margin-left: 5px;
  border: 1px solid #013a5d;
  border-radius: 5px;
  height: 20px;
  padding-left: 5px;
}
.date-of-birth {
  text-align: left;
  width: 40%;
  margin-left: 20px;
}
.sex {
  width: 20%;
}
.patient-id {
  width: 40%;
}

.table-input {
  display: flex;
  margin-top: 5px;
}
.date-of-birth-field {
  width: 40%;
  font-size: 16px;
  border: none;
  margin-left: 20px;
  text-align: left;
  font-family: "Medium";
}
.sex-field {
  width: 20%;
  border: none;
  font-size: 16px;
  text-align: center;
  font-family: "Medium";
}
.patient-id-field {
  width: 40%;
  border: none;
  text-align: center;
  font-family: "Medium";
  font-size: 16px;
}
.table-header-input-data {
  width: 100%;
}

.vertical-dot {
  height: 30px;
  widht: 10px;
  border: 1px solid blue;
}

.table-header {
  display: flex;
  color: white;
  margin-top: 20px;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: 3.5px solid #799fc3;
  background-color: #013a5d;
  text-align: center;
  align-items: center;
  font-size: 18px;
  font-family: "Medium";
}

.order-by {
  width: 45%;
  font-family: "Medium";
}
.orderby-name-field {
  display: flex;
  flex-direction: row;
}
.order-by-name {
  font-family: "Medium";
  font-weight: 600;
  letter-spacing: 1.2px;
}

.order-by-name-value {
  font-family: "Bold";
  font-weight: 600;
  margin-left: 5px;
  padding-left: 5px;
  border-radius: 5px;
  border: 1px solid #013a5d;
  height: 20px;
  font-size: 16px;
}

.report-recipient {
  width: 50%;
  font-family: "Medium";
}

.contact-info {
  width: 50%;
  font-family: "Medium";
}

.report-recipient-field {
  width: 49%;
  border: none;
  text-align: start;
  margin-right: 10px;
  margin-top: 5px;
  margin-left: 15px;
  height: 60px;
  font-family: "Medium";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.contact-info-field {
  width: 50%;
  border: none;
  text-align: start;
  margin-top: 5px;
  margin-left: 5px;
  height: 60px;
  font-family: "Medium";
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.table-header-input-data .table-amended-notes {
  padding: 0 10px 0 20px;
}
.table-header-input-data .table-amended-notes textarea {
  border: none;
  width: 100%;
  text-align: start;
  margin-top: 5px;
  height: 60px;
  font-family: "Medium";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
}