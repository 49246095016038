.report-preveiw.pdf-download * {
  font-family: "Gill Sans" !important;
}
/* .report-preveiw.pdf-download .custom-chart-container *,
.custom-chart-container * {
  font-family: "Times New Roman", Times, serif !important;
} */

.report-preveiw {
  height: 815px;
  width: 595px;
  background-color: green;
  position: absolute;
  z-index: 125;
  /* top: 20px; */
  padding-top: 10px;
  background-color: white;
  box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.15);
}

.report-preveiw-api {
  width: 21cm;
  /* height: 29.7cm; */
  /* margin: 5mm 45mm 5mm 45mm; */
  background-color: green;
  position: absolute;
  z-index: 125;
  /* top: 20px; */
  /* padding: 10px 0 0 20px; */
  background-color: white;
  /* box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.15); */
  /* background-color: rgba(102, 51, 153, 0.161); */
}

.top {
  margin-left: 22px;
  display: flex;
  jsutify-content: space-evenly;

}
.top-api {
  margin-left: 22px;
  display: flex;
  
  /* gap: 115px; */
}
.report-preveiw-api .top-api {
  margin-left: 0;
  margin: 0 15px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
}
.top-one {
  display: flex;
  gap: 1px;
}
.report-preveiw-api .top-one {
  padding-top: 10px;
}
.pdfview-patient-table {
  margin-top: -10px;
}
.report-preveiw-api .pdfview-patient-table {
  margin-top: -15px;
}
.preview-test-report {
  margin-top: 12px;
  padding-left: 5px;
}
.report-preveiw-api .preview-test-report img {
  margin-top: 10px;
}
.pdfview-final-report-section {
  margin-left: 70px;
  margin-top: -14px;
}
.pdfview-final-report-section-api {
  
}
.pdfview-final-report {
  background-color: #633477;
  color: white;
  text-align: center;
  font-family: "Bold";
  padding: 3px 35px 3px 35px;
  font-size: 7pt;
  border-radius: 10px;
  font-weight: 700;
  margin-top: 10px;
  /* letter-spacing: 0.1px; */
  text-align: center;
  width: 105px;
}

.report-preveiw-api .pdfview-final-report {
  width: 160px;
  font-size: 10pt;
  margin-top: 15px;
  padding: 5px 35px 1px 35px;
}


.pdf-download .pdfview-final-report {
  padding: 2.5px 35px 3.5px 35px;
}

.pdfview-labratory-director {
  font-size: 10px;
  margin-top: 4px;
  font-weight: 500;
  margin-left: 4px;
  word-spacing: 2px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  gap: 2.5px;
}

.report-preveiw-api .pdfview-labratory-director {
  font-weight: 400;
  width: 212px;
  font-size: 12px;
  margin-top: 5px;
}

.pdfview-labratory-director-name {
  font-family: "Bold";
  font-size: 11px;
  font-weight: 600;
  margin-left: 4px;
  word-spacing: 0px;
}
.report-preveiw-api .pdfview-labratory-director-name {
  font-family: 'Normal';
  font-size: 13px;
}

.pdfview-psi-history-test-result {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 30px;
  margin-left: 25px;
  margin-top: -22px;
}

.report-preveiw-api .pdfview-psi-history-test-result {
  display: grid;
  margin-left: 0;
  margin: 0 15px;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  /* justify-content: ; */
}

.pdfview-tka-section {
  width: 92%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
}

.report-preveiw-api .pdfview-tka-section {
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
  margin-top: 5px;
  /* margin-bottom: 5px; it's okay for localhost */
}

.pdfview-tka-bar {
  width: 98%;
  height: 15px;
  border-radius: 20px;
  border: 2.5px solid #799fc3;
  background-color: #013a5d;
  align-self: center;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 45px;
  padding: 3px 0;
  font-weight: 700;
}
.report-preveiw-api .pdfview-tka-bar {
  /* margin: 0 15px; */
  margin-left: 0;
  border: 3.5px solid #799fc3;
  height: 23px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 1px;
}
.pdfview-tka-full {
  font-family: "Medium";
  font-size: 9pt;
  letter-spacing: 0.6px;
}
.pdfview-tka {
  font-family: "Medium";
  font-size: 9.5pt;
  margin-left: 5px;
  /* letter-spacing: 0.8px; */
}
