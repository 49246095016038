.biovica-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 20px;
}

.login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  algin-items: center;
  text-align: center;
  background-color: grey;
}

.login-form-box {
  display: flex;
  flex-direction: column;
  width: 400px;
  align-self: center;
  position: relative;
  padding: 20px 20px;
  background-color: white;
  box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  height: 400px;
}

.field {
  width: 300px;
  height: 60px;
  font-family: "Normal";
}

.field-box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.login-button-box {
  display: flex;
  justify-content: center;
}
.login-button {
  background-color: #013a5d;
  height: 40px;
  width: 100px;
  text-align: center;
  color: white;
  font-family: "Medium";
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
