.preview-test-result-history {
  width: 43%;
  /* margin-top: 5px; */
}
.report-preveiw-api .preview-test-result-history {
  width: calc(100% - 30px);
}
.preview-test-result-history-header {
  width: 92%;
  height: 15px;
  border: 2.5px solid #00395b;
  background-color: #dbe5ef;
  border-radius: 20px;
  display: flex;
  color: #633477;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  font-weight: 600;
  font-family: "Medium";
  font-size: 10px;
  letter-spacing: 0.5px;
  padding: 3px 0;
}
.report-preveiw-api .preview-test-result-history-header {
  width: 100%;
  height: 23px;
  font-size: 13px;
  border: 3.5px solid #00395b;
  padding-top: 5px;
  padding-bottom: 1px;
}
.pdf-download .preview-test-result-history-header span {
  margin-top: -2px;
}

.preview-divtum-unit {
  text-align: cneter;
  align-content: center;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 5px;
  font-family: "Bold";
  font-weight: 600;
  color: #633477;
  font-size: 10px;
  gap: 2px;
}

.report-preveiw-api .preview-divtum-unit {
  font-size: 12px;
  font-family: 'Normal';
}

.reference-dua {
  color: #633477;
  text-align: center;
  font-size: 8px;
  font-family: "Bold";
  font-weight: 600;
  margin-top: 2px;
}
.report-preveiw-api .reference-dua {
  font-size: 10px;
  font-family: 'Normal';
}
.preview-test-result-history-data {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 10px;
  /* width: 90%; */
}

.preview-items-column {
  width: 50%;
}

.preview-each-test {
  padding-left: 2px;
  height: 18px;
  display: flex;
  /* border-bottom: 0.25px solid #c8d8e6; */
  border-right: 1px solid rgb(118, 160, 196);
  text-align: center;
  align-items: center;
  position: relative;
}
.report-preveiw-api .preview-each-test {
  height: 24px;
}
.preview-items-column:last-child .preview-each-test {
  border-right: none;
}

.preview-each-test::after {
  content: "";
  position: absolute;
  left: 0.25px;
  right: 0.25px;
  bottom: 0;
  height: 0.2px;
  background-color: rgb(118, 160, 196);
  opacity: 1;
}

.preview-test-date {
  width: 80%;
  align-self: center;
  font-size: 8px;
  font-family: "Medium";
  /* font-weight: 600; */
  /* letter-sapcing: 8.9px; */
}

.report-preveiw-api .preview-test-date {
  font-size: 10px;
}

.preview-test-value {
  width: 50%;
  align-self: center;
  font-size: 9px;
  font-family: "Medium";
  font-weight: 700;
  margin-right: 10px;
  display: grid;
  justify-content: end;
}

.report-preveiw-api .preview-test-value {
  font-size: 11px;
}

.preview-most-recent-value {
  display: flex;
  width: 100%;
  height: 75%;
  width: calc(100% - 5px);
  border: 1px solid #013a5d;
  border-radius: 20px;
  background-color: #fbfbb7;
  text-align: center;
  margin-right: 2px;
}

.pdf-download .preview-most-recent-value .preview-test-date {
  margin-top: -1.5px;
}

.pdf-download .preview-most-recent-value .preview-test-value {
  margin-top: -1px;
}
.preview-most-recent-value .preview-test-value {
  margin-right: 5px;
}

/* .preview-most-recent-value .preview-test-value > span {
  margin-left: 5px;
} */

.preview-test-value span.less-than {
  margin-right: 1px;
}

/* .preview-test-value.less-than-100 > span {
  margin-left: -7px;
}

.preview-most-recent-value .preview-test-value.less-than-100 > span {
  margin-left: -2px;
} */