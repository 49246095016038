.custom-chart-container {
  margin: 30px;
  /* margin-bottom: 300px; */
  /* padding-bottom: 10px; */
  overflow: visible;
}

.report-preveiw.pdf-download .custom-chart-container * {
  font-family: "Arial Custom" !important;
}

.custom-chart-container .main-chart {
  position: relative;
  padding-top: 10px;
  padding-right: 30px;
}

.custom-chart-container .main-chart .y-legend {
  position: absolute;
  left: 25px;
  font-weight: bold;
  top: 50%;
  transform: rotate(-90deg) translateY(-50%);
}

.custom-chart-container .main-chart .main-chart-row {
  height: 21px;
  display: grid;
  grid-template-columns: 70px 1fr;
  align-items: center;
}

.custom-chart-container .main-chart-row .row-item-left {
  text-align: right;
  padding: 0 5px;
  font-size: 6pt;
}

.custom-chart-container .main-chart-row .row-item-right {
  background-color: #819ec0;
  height: 1px;
}

.custom-chart-container .main-chart-row.row-item-200,
.custom-chart-container .main-chart-row.row-item-100 {
  margin-top: 42px;
}

.custom-chart-container .main-chart-row.row-item-250,
.custom-chart-container .main-chart-row.row-item-145 {
  position: absolute;
  width: calc(100% - 30px);
  left: 0;
  bottom: 0;
}

.custom-chart-container .main-chart-row.row-item-250 {
  bottom: 95px;
}
.custom-chart-container .main-chart-row.row-item-145 {
  bottom: calc((63px / 100.0) * 45);
}

.custom-chart-container .main-chart-row.row-item-250 .row-item-left,
.custom-chart-container .main-chart-row.row-item-145 .row-item-left {
  font-weight: bold;
  position: relative;
}

.custom-chart-container .main-chart-row.row-item-250 .row-item-left .left-label,
.custom-chart-container .main-chart-row.row-item-145 .row-item-left .left-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10px;
}

.custom-chart-container .main-chart-row.row-item-250 .row-item-left .left-label span,
.custom-chart-container .main-chart-row.row-item-145 .row-item-left .left-label span {
  display: block;
  font-size: 5pt;
}

.custom-chart-container .main-chart-row.row-item-250 .row-item-right,
.custom-chart-container .main-chart-row.row-item-145 .row-item-right {
  height: 0;
  background-color: transparent;
  border-top: 3px dashed rgb(121, 159, 195);
}

.custom-chart-container .main-chart-row.row-item-145 .row-item-right {
  border: none;
}

.custom-chart-container .left-bar {
  position: absolute;
  left: 70px;
  top: 0px;
  width: 1px;
  height: 310px;
  background-color: rgb(0, 58, 93);
}

.custom-chart-container .bottom-bar {
  position: absolute;
  left: 70px;
  bottom: -7px;
  height: 1px;
  width: calc(100% - 70px - 30px);
  background-color: rgb(0, 58, 93);
}
/* ------------------------- */
.custom-chart-container .datepoint-container {
  padding: 30px 0 40px 35px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 110px);
  /* grid-template-columns: repeat(8, 1fr); */
  gap: 10px;
  position: relative;
}
.custom-chart-container .datepoint-container .date-legend {
  position: absolute;
  left: -25px;
  top: 30px;
}

.custom-chart-container .datepoint-container .pre-treatment {
  position: absolute;
  bottom: 40px;
  left: 36px;
}


.custom-chart-container .datepoint-container * {
  box-sizing: border-box;
}
.custom-chart-container .datepoint-item {
  position: relative;
  display: grid;
  gap: 10px;
  
  justify-items: center;
}
.custom-chart-container .datepoint-item:not(:last-child)::after {
  content: "";
  position: absolute;
  height: 23px;
  width: 3px;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(0, 58, 93);
}

.custom-chart-container .datepoint-item .point {
  font-size: 12px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: #dce6f0;
  z-index: 50;
  font-weight: 700;
  padding: 0 5px;
  color: #211e1f;
  border: solid 1px rgb(0, 58, 93);
  border-radius: 20px;
  opacity: 1;
}

.custom-chart-container .datepoint-item .point * {
  padding: 0;
  margin: 0;
}

.custom-chart-container .datepoint-item input,
/* .custom-chart-container .datepoint-item button:not(.react-datepicker__navigation--next),
.custom-chart-container .datepoint-item button:not(.react-datepicker__navigation--previous), */
.custom-chart-container .datepoint-item button:not(.react-datepicker__navigation) {
  width: 100% !important;
  border: 1px solid #384149;
  border-radius: 5px;
  padding: 2px;
}

.datepoint-item input {
  font-family: Arial, Helvetica, sans-serif !important;
}


.custom-chart-container .last-box {
  position: absolute;
  top: -340px;
  bottom: 0;
  width: calc(100% + 10px);
  border: 1.5px solid #343132;
  z-index: -1;
}


.report-preveiw .custom-chart-container span,
.custom-chart-container span {
  font-family: Arial, Helvetica, sans-serif !important;
}