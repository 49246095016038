.wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.main-wrap {
  width: 90.5%;
  align-items: center;
}
.logout-button-user {
  display: flex;
  margin-bottom: 50px;
  margin-top: 20px;
  width: auto;
  justify-content: space-between;
  cursor: pointer;
}
.logout-button-user-wrap {
  display: flex;
  justify-content: end;
  width: 96.5%;
}
.user-name {
  text-align: center;
  align-content: center;
  align-items: center;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Medium";
  font-size: 18px;
  border: 2px solid #00395b;
  padding: 0 10px;
  border-radius: 10px;
  margin: 0 10px 0 0;
}
.logout-button {
  height: 40px;
  width: 120px;
  border-radius: 10px;
  background-color: #00395b;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  gap: 5px;
  width: 150px;
}

.logout {
  font-size: 20px;
  font-family: "Medium";
}
.logout-icon {
  height: 20px;
}
.header-section {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 80px;
}

.headline {
  font-size: 25.65pt;
  margin-top: 5px;
  color: #00395b;
  /* letter-spacing: 2px; */
  text-align: center;
  font-family: "Bold";
  margin-left: 350px;
}

.header-log {
}

.unproofed {
  font-family: "Heavy";
  font-size: 13pt;
  color: #d2232a;
  margin-left: 12px;
  font-weight: 900;
  letter-spacing: 0.1px;
}

.divitum-tka {
  display: flex;
}
.divitum {
  font-size: 25.95pt;
  font-family: "Medium";
  font-weight: 700;
}
.dashboard-r-circle {
  font-size: 16px;
  font-weight: 900;
  margin-left: -2px;
  margin-top: 5px;
  font-family: "Bold";
}

.tka-text {
  font-size: 25.95pt;
  font-family: "Medium";
  font-weight: 700;
  margin-left: 15px;
}

.psi-history-test-result {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 30px;
  margin-left: 80px;
}

.test-result-history {
  width: 50%;
  margin-top: 40px;
}

.test-result-history-header {
  width: 82%;
  height: 40px;
  border: 3px solid #013a5d;
  background-color: #00395b;
  border-radius: 20px;
  display: flex;
  color: purple;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  font-weight: 600;
  font-fmaily: "Medium";
}

.report-button {
  margin-top: 20px;
  background-color: #633477;
  width: 200px;
  color: white;
  padding: 5px;
  border-radius: 10px;
  letter-spacing: 1px;
}
.final-report-button {
  display: flex;
  justify-content: start;
  text-align: center;
  font-size: 11pt;
  font-family: "Medium";
  flex-direction: column;
  width: 250px;
  margin-top: -50px;
  margin-bottom: 20px;
  align-items: start;
  margin-right: 20px;
}
.director-name {
  margin-top: 10px;
}

.director-name-value {
  border: 1 px solid #013a5d;
  border-radius: 5px;
  padding: 2px;
  font-family: "Bold";
}

.tka-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
}

.tka-bar {
  width: 92%;
  height: 40px;
  border-radius: 20px;
  border: 3.5px solid #799fc3;
  background-color: #013a5d;
  align-self: center;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
}
.tka-full {
  font-family: "Medium";
  font-size: 18pt;
  letter-spacing: 0.2px;
}
.tka {
  font-family: "Medium";
  font-size: 18pt;
  margin-left: 5px;
  letter-spacing: 0.8px;
}
.tka-data {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: black;
  justify-content: center;
  margin-top: 20px;
}

.graph-input {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.date-input {
  margin-right: 10px;
  height: 30px;
  border: 1px solid purple;
  border-radius: 10px;
  padding: 5px;
  width: 150px;
}

.y-axis-input {
  border: 1px solid purple;
  margin-right: 10px;
  border-radius: 10px;
  padding-left: 10px;
}

.input-button {
  background-color: purple;
  color: white;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80px;
  cursor: pointer;
  height: 40px;
  align-self: center;
  margin-bottom: 10px;
}

.date-point-enter-wrap {
  display: flex;
  flex-direction: row;
}

.date-point-wrap {
  margin-top: 20px;
  margin-bottom: 50px;
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.date-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.date {
  font-size: 18px;
  font-weight: 600;
  color: black;
  width: 80px;
  text-align: center;
  align-content: center;
  padding: 5px 0;
}

.point-row {
  display: flex;
  flex-direction: row;
}

.value {
  font-size: 18px;
  font-weight: 600;
  color: black;
  width: 80px;
  text-align: center;
  padding: 5px 0;
}
.point-input {
  margin-right: 10px;
  height: 30px;
  border: 1px solid purple;
  border-radius: 10px;
  padding: 5px;
  width: 150px;
}

.preview-button {
  height: 40px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: #013a5d;
  border-radius: 10px;
  color: white;
  font-family: "Medium";
  font-size: 18px;
  width: 150px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 80px;
  cursor: pointer;
}

.blur-background {
  height: 2100px;
  width: 100%;
  z-index: 100;
  background-color: grey;
  position: absolute;
  top: 0px;
  opacity: 90%;
}

.preview-convert {
  display: flex;
  justify-content: center;
  gap: 20px;
}
