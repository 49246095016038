.order-table {
  width: 45%;
  margin-top: 40px;
  flex-direction: row;
  align-items: center;
}

.patient-sample-information-header {
  width: 100%;
  height: 40px;
  border: 2.5px solid #799fc3;
  background-color: #013a5d;
  border-radius: 20px;
  display: flex;
  color: white;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  font-family: "Medium";
  letter-spacing: 0.7px;
  font-size: 18px;
}

.filed-value {
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: start;
  margin-top: 10px;
  padding-bottom: 5px;
  font-family: "Medium";
  font-weight: 600;
  align-self: center;
  margin-left: 20px;
  font-size: 14px;
}

.under-line {
  width: 92%;
  height: 1px;
  background-color: #799fc3;
  margin-left: 20px;
  margin-top: 2px;
}

.input-value {
  border: none;
  width: 30%;
  margin-right: 20px;
  font-family: "Medium";
  font-weight: 600;
  margin-left: 150px;
  font-size: 16px;
  letter-spacing: 0.1px;
}

.filed {
  width: 30%;
  text-align: start;
  margin-left: 20px;
  font-size: 15px;
  font-family: "Normal";
}
