.test-result-history {
  width: 45%;
  margin-top: 20px;
}

.test-result-history-header {
  width: 100%;
  height: 40px;
  border: 3.5px solid #00395b;
  background-color: #dbe5ef;
  border-radius: 20px;
  display: flex;
  color: #633477;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  font-weight: 600;
  font-family: "Medium";
  font-size: 18px;
}

.divtum-unit {
  text-align: cneter;
  font-family: "Medium";
  align-content: center;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 10px;
  marign-bottom: 20px;
  font-weight: 600;
  color: #633477;
}

.test-result-history-data {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 10px;
}

.items-column {
  width: 49%;
}

.each-test {
  height: 30px;
  display: flex;
  border-bottom: 1px solid #83a6c8;
  border-right: 2px solid #83a6c8;
  text-align: center;
  align-content: center;
}

.items-column:last-child .each-test {
  border-right: none;
}

.test-date {
  width: 50%;
  align-self: center;
  font-family: "Medium";
  text-align: center;
}

.test-value {
  width: 50%;
  align-self: center;
  font-family: "Bold";
  font-size: 18px;
  padding-right: 15px;
  display: grid;
  justify-content: end;
}

.most-recent-value {
  display: flex;
  width: 100%;
  height: 80%;
  width: calc(100% - 10px);
  border: 2px solid #013a5d;
  border-radius: 20px;
  background-color: #fbfbb7;
  text-align: center;
}

.most-recent-value .test-value {
  padding-right: 5px;
}

.items-column:last-child .each-test {
  border-right: none;
}
