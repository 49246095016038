body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Bold"; /*Can be any text*/
  src: local("Gill Sans Bold"),
    url("./fonts/Gill Sans Bold.ttf") format("truetype");
}

@font-face {
  font-family: "CondensedBold"; /*Can be any text*/
  src: local("GillSans Condensed Bold"),
    url("./fonts/GillSans Condensed Bold.otf") format("truetype");
}

@font-face {
  font-family: "Light"; /*Can be any text*/
  src: local("Gill Sans Bold"),
    url("./fonts/Gill Sans Bold.otf") format("truetype");
}

@font-face {
  font-family: "Medium"; /*Can be any text*/
  src: local("Gill Sans Medium"),
    url("./fonts/Gill Sans Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Normal"; /*Can be any text*/
  src: local("Gill Sans"), url("./fonts/Gill Sans.ttf") format("truetype");
}

@font-face {
  font-family: "Heavy"; /*Can be any text*/
  src: local("Gill Sans Heavy"),
    url("./fonts/Gill Sans Heavy.otf") format("truetype");
}

button {
  cursor: pointer;
}

/* ----------- Global ------------ */
.pdf-download span.pdf-ml-2px {
  margin-left: 2px;
}
.pdf-download span.pdf-ml--2px {
  margin-left: -2px;
}
.pdf-download span.pdf-mr-2px {
  margin-right: 2px;
}
.pdf-download span.pdf-mr--2px {
  margin-right: -2px;
}

.pdf-download span.pdf-ml--3px {
  margin-left: -3px;
}

.pdf-download span.pdf-ml-1px {
  margin-left: 1px;
}
.pdf-download span.pdf-ml--1px {
  margin-left: -1px;
}
.pdf-download span.pdf-mr-1px {
  margin-right: 1px;
}
.pdf-download span.pdf-mr--1px {
  margin-right: -1px;
}

.pdf-download span.pdf-ml--1-5px {
  margin-left: -1.5px;
}

/* ----------- Global ------------ */

/* ----------- Start For Upload Button ------*/
.spinner {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border-top: 0.4rem solid #fff;
  border-right: 0.4rem solid #fff;
  border-bottom: 0.4rem solid #fff;
  border-left: 0.4rem solid #ccc;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader .spinner {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border-top: 0.4rem solid #ccc;
  border-right: 0.4rem solid #ccc;
  border-bottom: 0.4rem solid #ccc;
  border-left: 0.4rem solid #fff;
  animation: spinner 0.8s linear infinite;
}
/* ----------- End For Upload Button ------*/