.preview-constant-section {
  width: 90%;
  margin-left: 30px;
}

.preview-constant-section * {
  color: rgb(33, 33, 33);
}

.preview-chart-static-note {
  font-size: 7px;
  font-weight: 600;
  font-family: "Normal";
}

.report-preveiw-api .preview-chart-static-note {
  font-size: 9px;
  font-weight: normal;
}

.preview-horizontal-dotted-line {
  width: 100%;
  height: 1px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-bottom: 1px dotted #799fc3;
}

.preview-common-header {
  font-family: "Normal";
  font-size: 8px;
  font-weight: 600;
}

.report-preveiw-api .preview-common-header {
  font-weight: normal;
  font-size: 10px;
}

.pdf-download .preview-common-header span.semicolon1 {
  margin-left: -3px;
}

.pdf-download .preview-common-header span.semicolon2 {
  margin-left: -4px;
}

.preview-general-info-limitations {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  gap: 15px;
}

.preview-warnings {
  width: 580px;
}
ul {
  margin-top: 2px;
}
.list-preview {
  font-family: "Normal";
  font-size: 6px;
  font-weight: 600;
  padding-left: 5px;
}

.report-preveiw-api .list-preview {
  font-size: 8px;
  font-weight: normal;
  margin-bottom: 5px;
}

.list-preview li {
  list-style: none;
  position: relative;
}

.list-preview li::before {
  content: '';
  position: absolute;
  left: -4px;
  top: 3px;
  height: 2px;
  width: 2px;
  background-color: rgb(33, 33, 33);
  border-radius: 50%;
}

.preview-reference-section {
  font-size: 6px;
  font-weight: 400;
  font-family: "Medium";
}
.report-preveiw-api .preview-reference-section {
  font-size: 8px;
  /* letter-spacing: 0.4px; */
  font-weight: 400;
  line-height: 1.2;
}
.preview-reference {
  font-weight: 600;
  font-family: "Bold";
  font-size: 6px;
}

.report-preveiw-api .preview-reference {
  font-size: 8px;
  font-family: "Normal";
  font-weight: normal;
  /* line-height: 1.3; */
}

.preview-divider {
  width: 100%;
  height: 5px;
  background-color: #013a5d;
  border-radius: 20px;
  margin-top: 5px;
}

.preview-footer {
  display: flex;
  width: 100%;
  margin-top: 5px;
  justify-content: space-between;
}

.preview-footer-left {
  width: 70%;
  font-size: 6px;
  font-family: "Normal";
  font-weight: 600;
  line-height: 9px;
}

.preview-left-footer-text {
  /* margin-bottom: 5px; */
  font-size: 6px;
  font-weight: normal;
}
.report-preveiw-api .preview-left-footer-text {
  font-size: 9px;
}
.preview-bdt1002 {
  margin: 0 10px;
}

.preview-footer-right {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.preview-biovica-logo img {
  width: 120px;
  margin-left: -3px;
}

.preview-address,
.preview-telephone {
  font-family: "Bold";
  font-size: 6px;
  text-align: start;
  display: flex;
  flex-direction: column;
}
.report-preveiw-api .preview-address,
.report-preveiw-api .preview-telephone {
  font-size: 8px;
}
.preview-telephone {
  margin-top: 2px;
}
.preview-biovica-web-link {
  text-decoration: none;
  font-family: "Normal";
  font-size: 10px;
  margin-top: 3px;
}
.report-preveiw-api .preview-biovica-web-link {
  font-size: 12px;
  margin-top: 0;
}
.preview-left-footer-copyright {
  display: flex;
  font-size: 6px;
  font-weight: normal;
}

.report-preveiw-api .preview-left-footer-copyright {
  font-size: 9px;
  font-weight: normal;
  line-height: 1.5;
}

.preview-left-footer-copyright a {
  text-decoration: none;
}
.preview-r-circle {
  font-size: 4px;
  margin-top: -2px;
  margin-right: 2px;
  margin-left: -0.5px;
}


